import React from "react";
import {
  Wrapper,
  Container,
  Grid,
  GridItem,
  Description,
  Strong,
  Info,
  ImageHolder,
} from "./InformationStyled";

import { StaticImage } from "gatsby-plugin-image";

const Information = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <GridItem>
            <Description>
              PDF Pro Software Inc was started in 2017, and immediately began
              work to build and to market it’s PDF Editing application, PDF Pro.
              The company currently counts over a million copies sold. Although
              we are a small team, we have aspirations to make the process of
              working with documents easier and more intuitive.
            </Description>
          </GridItem>
          <GridItem>
            <Description>
              <Strong>Headquarters:</Strong>
            </Description>
            <Info>2015 Main St #2, Vancouver, BC V5T 3C2, Canada</Info>
            <Description>
              <Strong>Registered Office:</Strong>
            </Description>
            <Info>1383 W 8th Ave, Vancouver, BC V6H 3V9, Canada</Info>
            <Description>
              <Strong>Staff: 8</Strong>
            </Description>
          </GridItem>
          <GridItem>
            <ImageHolder>
              <StaticImage
                src={"../images/AboutImage1.jpeg"}
                alt="AboutImage 1"
              />
            </ImageHolder>
          </GridItem>
          <GridItem>
            <ImageHolder>
              <StaticImage
                src={"../images/AboutImage2.jpeg"}
                alt="AboutImage 2"
              />
            </ImageHolder>
          </GridItem>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Information;
