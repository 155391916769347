import React from "react";
import {
  Wrapper,
  Container,
  Title,
  ItemHolder,
  Item,
  SubText,
  Center,
  AnckerLink,
} from "./ContactUsStyled";
import Arrow from "../icons/Arrow.svg";
import LinkedIn from "../icons/LinkedIn.svg";
import Support from "../icons/Support.svg";
import Mail from "../icons/MailContacts.svg";

const ContactUs = () => {
  return (
    <Wrapper>
      <Container>
        <Title>Contact Us</Title>
        <Center>
          <ItemHolder>
            <AnckerLink
              href="https://www.linkedin.com/company/zipitysoftware"
              rel="noreferrer noopener"
              target="_blank"
              className="img-row4"
            >
              <Item>
                <LinkedIn />
                <SubText>Linkedin</SubText>
                <Arrow />
              </Item>
            </AnckerLink>
            <AnckerLink
              href="https://support.pdfpro.com/hc/en-us/requests/new"
              rel="noreferrer noopener"
              target="_blank"
              className="img-row4"
            >
              <Item>
                <Support />
                <SubText>
                  Submit Support<br></br>Request
                </SubText>
                <Arrow />
              </Item>
            </AnckerLink>
            <AnckerLink href="mailto: ceo@pdfpro.com">
              <Item>
                <Mail />
                <SubText>
                  Email the<br></br>Management Team
                </SubText>
                <Arrow />
              </Item>
            </AnckerLink>
          </ItemHolder>
        </Center>
      </Container>
    </Wrapper>
  );
};

export default ContactUs;
