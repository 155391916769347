import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  border-style: solid;
  border-color: #aaaaaa8e;
  border-width: 1px;
  background-color: #fbfbfb;
  width: 100%;
`;

export const Container = styled.div`
  margin: 0 auto;
  margin-top: 100px;
  max-width: 80vw;
  max-width: 1440px;

  @media (max-width: 815px) {
    margin: 0 32px;
    margin-top: 40px;
  }
`;

export const TitleHolder = styled.div``;

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 52px;
  line-height: 58px;
  text-align: center;

  @media (max-width: 515px) {
    font-weight: 300;
    font-size: 32px;
    line-height: 36px;
    text-align: start;
  }
`;

export const SubTitle = styled.h2`
  margin-top: 24px;
  margin-bottom: 64px;
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
  text-align: center;

  color: #111111;

  @media (max-width: 515px) {
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    color: #111111;
    text-align: start;

    margin-bottom: 30px;
  }
`;

export const Strong = styled.strong`
  font-weight: 500;
`;

export const QuoteHolder = styled.div`
  position: relative;
  margin: 0 auto;
  margin-bottom: 108px;
  padding: 40px;
  width: 723px;
  background: #ffffff;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  @media (max-width: 815px) {
    max-width: 80vw;
    max-width: 1440px;
    width: auto;
  }

  @media (max-width: 550px) {
    padding: 20px;
  }
`;

export const Paragraph = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #222222;
  width: 650px;

  @media (max-width: 750px) {
    width: auto;
  }

  @media (max-width: 515px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const IconHolder = styled.div`
  position: absolute;
  top: -15px;
`;

export const CoFounderInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: start;

    gap: 30px;
  }
`;

export const DescriptionHolder = styled.div``;

export const PersonDetail = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const ImageHolder = styled.div`
  border-radius: 50%;
  max-height: 60px;
  max-width: 60px;
`;

export const Img = styled.img`
  max-height: 60px;
  max-width: 60px;
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #222222;
`;
export const Descritpion = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #222222;
`;

export const Contact = styled.div`
  display: flex;
  gap: 10px;
`;
