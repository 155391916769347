import React from "react";
import {
  Wrapper,
  Container,
  Title,
  Strong,
  SubText,
  StyledLink,
} from "./JoinTeamStyled";

const JoinTeam = () => {
  return (
    <Wrapper>
      <Container>
        <Title>
          Are you interested in <Strong>joining our team?</Strong>
        </Title>
        <SubText>
          We are always on the lookout for great people.<br></br> Please visit
          our <StyledLink to="/careers">careers page</StyledLink> to find and
          apply to new opportunities.
        </SubText>
      </Container>
    </Wrapper>
  );
};

export default JoinTeam;
