import React from "react";
import {
  Container,
  Title,
  Wrapper,
  Strong,
  SubTitle,
  TitleHolder,
  Paragraph,
  QuoteHolder,
  IconHolder,
  CoFounderInformation,
  ImageHolder,
  DescriptionHolder,
  Name,
  Descritpion,
  Contact,
  PersonDetail,
} from "./QuoteStyked";
import QuoteIcon from "../icons/QuoteIcon.svg";
import LinkedIn from "../icons/LinkedInOriginal.svg";
import Mail from "../icons/Mail.svg";
import { StaticImage } from "gatsby-plugin-image";

const Quote = () => {
  return (
    <Wrapper>
      <Container>
        <TitleHolder>
          <Title>
            About <Strong>PDF Pro Software Inc.</Strong>
          </Title>
          <SubTitle>
            The company behind <Strong>PDF Pro</Strong>
          </SubTitle>
        </TitleHolder>
        <QuoteHolder>
          <IconHolder>
            <QuoteIcon />
          </IconHolder>
          <Paragraph>
            “We strive to create no-nonsense PDF software: Easy to use,
            lightweight, and that’s fairly priced.”
          </Paragraph>
          <CoFounderInformation>
            <PersonDetail>
              <ImageHolder>
                <StaticImage
                  src={"../images/edmon-img.png"}
                  alt="Founder Profile Picture"
                  imgStyle={{ borderRadius: "50%" }}
                />
              </ImageHolder>
              <DescriptionHolder>
                <Name>Edmon Moren</Name>
                <Descritpion>Co-Founder, PDF Pro Software Inc.</Descritpion>
              </DescriptionHolder>
            </PersonDetail>
            <Contact>
              <a
                href="https://www.linkedin.com/in/edmonmoren/"
                rel="noreferrer noopener"
                target="_blank"
                className="linked-icon"
              >
                <LinkedIn />
              </a>
              <a href="mailto:ceo@pdfpro.com">
                <Mail />
              </a>
            </Contact>
          </CoFounderInformation>
        </QuoteHolder>
      </Container>
    </Wrapper>
  );
};

export default Quote;
