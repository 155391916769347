import React from "react";

import Seo from "../components/shared/seo";
import Quote from "../components/About/Quote/Quote";
import Information from "../components/About/Information/Information";
import JoinTeam from "../components/About/JoinTeam/JoinTeam";
import ContactUs from "../components/About/ContactUs/ContactUs";

function about() {
  return (
    <>
      <Seo title="PDF Reader - About us" special={true} />
      <Quote />
      <Information />
      <JoinTeam />
      <ContactUs />
    </>
  );
}

export default about;
