import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const Container = styled.div`
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 80px;
  width: 80vw;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;

  @media (max-width: 1070px) {
    grid-template-columns: 1fr;
  }
`;

export const GridItem = styled.div`
  padding: 12px;
`;

export const Description = styled.p`
  margin: 0;
  padding-bottom: 6px;
  width: 510px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #222222;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Info = styled.p`
  margin: 0;
  width: 510px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #222222;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Strong = styled.strong`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #222222;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const ImageHolder = styled.div`
  max-width: 588px;
  max-height: 420px;
`;
