import styled from "styled-components";
import { Link } from "gatsby";

export const Wrapper = styled.div`
  margin: 0 auto;
  border-style: solid;
  border-color: #aaaaaa8e;
  border-width: 1px;
  background-color: #fbfbfb;
  width: 100%;
`;

export const Container = styled.div`
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 80vw;
  max-width: 1440px;

  @media (max-width: 600px) {
    margin: 0 32px;
    margin-top: 40px;
  }
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #111111;

  @media (max-width: 450px) {
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    text-align: start;
  }
`;

export const Strong = styled.strong`
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #111111;

  @media (max-width: 450px) {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: start;
  }
`;

export const SubText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #222222;
  text-align: center;

  @media (max-width: 450px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: start;
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-decoration: underline;
  color: #007de6;
`;
