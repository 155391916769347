import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const Container = styled.div`
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 80vw;
  max-width: 1440px;

  @media (max-width: 600px) {
    margin: 0 32px;
    margin-bottom: 40px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 450px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const SubText = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #111111;
`;

export const ItemHolder = styled.div`
  margin-left: 9%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 786px) {
    margin: auto;
    flex-direction: column;
    gap: 67px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const Center = styled.div`
  margin: 0 auto;
`;

export const AnckerLink = styled.a`
  text-decoration: none;
`;
